import React, {lazy} from "react"
import { Spin } from 'antd';
import { RouteObject } from "react-router-dom"

const Index = lazy(() => import("../pages/index"))
const Software = lazy(() => import("../pages/softwareProduct/index"))
const Layout = lazy(() => import("../components/Layout"))
const SoftwareSystem = lazy(() => import("../pages/softwareProduct/software-system"))
const HistoryIndex = lazy(() => import("../pages/softwareProduct/history/index"))
const MonitorsIndex = lazy(() => import("../pages/softwareProduct/monitorsystem/index"))
const JoinTheTeam = lazy(() => import("../pages/joinTheTeam/index"))
const PxTeam = lazy(() => import("../pages/pxTeam/index"))
const ServiceContent = lazy(() => import("../pages/serviceContent/index"))
const ServiceSafety = lazy(() => import("../pages/serviceContent/safety"))
const FirmIndex = lazy(() => import("../pages/firmContent/index"))
const FirmStrategic = lazy(() => import("../pages/firmContent/strategic"))
const FirmStudies = lazy(() => import("../pages/firmContent/studies"))
const FirmStructure = lazy(() => import("../pages/firmContent/structure"))
const FirmCulture = lazy(() => import("../pages/firmContent/culture"))
const SafetyConsulting = lazy(() => import("../pages/safetyConsulting/index"))
const Scbm = lazy(() => import("../pages/safetyConsulting/scbm/scbm"))
const HsemscIndex = lazy(() => import("../pages/safetyConsulting/hsemsc/index"))
const RaIndex = lazy(() => import("../pages/safetyConsulting/ra/index"))
const StIndex = lazy(() => import("../pages/safetyConsulting/st/index"))
const HsetdIndex = lazy(() => import("../pages/safetyConsulting/hsetd/index"))
const EmIndex = lazy(() => import("../pages/safetyConsulting/em/index"))
const SpbcIndex = lazy(() => import("../pages/safetyConsulting/spbc/index"))
const NewsIndex = lazy(() => import("../pages/news/index"))
const NewsVideo = lazy(() => import("../pages/news/video-list"))
const NewsDetails = lazy(() => import("src/pages/news/news-details"))
const RouterList:RouteObject[] = [
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: '/',
                index: true,
                element: <React.Suspense fallback={<Spin />}>
                            <Index />
                        </React.Suspense>
                
            },
            {
                path: '/software',
                element: <React.Suspense fallback={<Spin />}>
                            <Software />
                        </React.Suspense>
            },
            {
                path: '/software-system',
                element: <React.Suspense fallback={<Spin />}>
                            <SoftwareSystem />
                        </React.Suspense>
            },
            {
                path: '/software-history',
                element: <React.Suspense fallback={<Spin />}>
                            <HistoryIndex />
                        </React.Suspense>
            },
            {
                path: '/software-monitors',
                element: <React.Suspense fallback={<Spin />}>
                            <MonitorsIndex />
                        </React.Suspense>
            },
            {
                path: '/join-stem',
                element: <React.Suspense fallback={<Spin />}>
                            <JoinTheTeam />
                        </React.Suspense>
            },
            {
                path: '/px-team',
                element: <React.Suspense fallback={<Spin />}>
                            <PxTeam />
                        </React.Suspense>
            },
            {
                path: '/service-team',
                element: <React.Suspense fallback={<Spin />}>
                            <ServiceContent />
                        </React.Suspense>
            },
            {
                path: '/service-safety',
                element: <React.Suspense fallback={<Spin />}>
                            <ServiceSafety />
                        </React.Suspense>
            },
            {
                path: '/firm',
                element: <React.Suspense fallback={<Spin />}>
                            <FirmIndex />
                        </React.Suspense>
            },
            {
                path: '/firm-strategic',
                element: <React.Suspense fallback={<Spin />}>
                            <FirmStrategic />
                        </React.Suspense>
            },
            {
                path: '/firm-studies',
                element: <React.Suspense fallback={<Spin />}>
                            <FirmStudies />
                        </React.Suspense>
            },
            {
                path: '/firm-structure',
                element: <React.Suspense fallback={<Spin />}>
                            <FirmStructure />
                        </React.Suspense>
            },
            {
                path: '/firm-culture',
                element: <React.Suspense fallback={<Spin />}>
                            <FirmCulture />
                        </React.Suspense>
            },
            {
                path: '/safety',
                element: <React.Suspense fallback={<Spin />}>
                            <SafetyConsulting />
                        </React.Suspense>
            },
            {
                path: '/safety-scbm',
                element: <React.Suspense fallback={<Spin />}>
                            <Scbm />
                        </React.Suspense>
            },
            {
                path: '/safety-hsemsc',
                element: <React.Suspense fallback={<Spin />}>
                            <HsemscIndex />
                        </React.Suspense>
            },
            {
                path: '/safety-ra',
                element: <React.Suspense fallback={<Spin />}>
                            <RaIndex />
                        </React.Suspense>
            },
            {
                path: '/safety-st',
                element: <React.Suspense fallback={<Spin />}>
                            <StIndex />
                        </React.Suspense>
            },
            {
                path: '/safety-hsetd',
                element: <React.Suspense fallback={<Spin />}>
                            <HsetdIndex />
                        </React.Suspense>
            },
            {
                path: '/safety-em',
                element: <React.Suspense fallback={<Spin />}>
                            <EmIndex />
                        </React.Suspense>
            },
            {
                path: '/safety-spbc',
                element: <React.Suspense fallback={<Spin />}>
                            <SpbcIndex />
                        </React.Suspense>
            },
            {
                path: '/news',
                element: <React.Suspense fallback={<Spin />}>
                            <NewsIndex />
                        </React.Suspense>
            },
            {
                path: '/news-video',
                element: <React.Suspense fallback={<Spin />}>
                            <NewsVideo />
                        </React.Suspense>
            },
            {
                path: "/news-details",
                element: <React.Suspense fallback={<Spin />}>
                            <NewsDetails />
                        </React.Suspense>
            },
            // 
            {
                path: '/404',
                element: <React.Suspense fallback={<Spin />}>
                            {/* <Software /> */}
                        </React.Suspense>
            }
        ]
    }
]
export default RouterList