import React from 'react';
import 'antd/dist/reset.css';
import type { FC } from 'react';
import { Outlet,useRoutes } from "react-router-dom"
// import { Button } from 'antd';
import RouterList from "./router/index"
import Index from "./pages/index"
import MenuTop from './components/MenuTop';
import Footer from './components/Footer';
import './App.css';
import './style/common.scss'
const App: FC = () => {
  const element  = useRoutes(RouterList)
  return element
}
export default App;
//  
  // <div className="App">
  //   <header className="App-header">
  //       <div>
  //         <div>
  //           <MenuTop></MenuTop>
  //         </div>
  //       </div>
  //       <div className='main'>
  //         <Outlet></Outlet>
  //       </div>
  //       <Footer></Footer>
  //   </header>
  // </div>
